import BaseBean from "@/utils/BaseBean";

export interface ICorpCardDataObj {
    utilInst:CorpCardUtil
    refMap:Map<string,any>
    formRef:any
    disabled:boolean
    parentTreeData: any
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class CorpCardUtil extends BaseBean{
    public dataObj:ICorpCardDataObj;

    constructor(proxy:any,dataObj:ICorpCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //改变状态
    public changeStatus(params:any):void{
        this.utils.Tools.configBox({
            message:'确定要执行该操作吗?',
            sureFn:async ()=> {
                let url = this.dataObj.compParams.modelPath + "/custom";
                let res = await this.utils.Api.postRequest({ url: url, params: params});
                if(res.result){
                    this.utils.Tools.success({ message: res.msg });
                    if (this.proxy.engine.engineParams.ownerComp && this.proxy.engine.engineParams.ownerComp.queryHandler) this.proxy.engine.engineParams.ownerComp.queryHandler();
                    await this.proxy.engine.engineUtil.doAddOrLoad(this.proxy.engine.id);
                }
            }
        });
    }
}
