import {getCurrentInstance,reactive,toRefs,onBeforeMount,onMounted,defineComponent,nextTick} from 'vue';
import CorpCardUtil,{ICorpCardDataObj} from "./corpCardUtil";
export default defineComponent ({
    name: "corpCard",
    title: "机构管理",
    modelType:'card',
    fullscreen: false,
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let checkTel = (rule:any, value:any, callback:any) => {
            const re = /^[0-9]*$/;
            if(value && !re.test(value)){
                callback(new Error(proxy.$t('corp.form.tel_rule')));
            }else{
                callback();
            }
        };
        let dataObj:ICorpCardDataObj=reactive<ICorpCardDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            formRef:null,
            disabled:false,
            parentTreeData: [],
            compParams: {modelPath: utils.Api.buildUrl("/corp")},
            form: {},
            rules: {
                code: [utils.UtilPub.commonValidRule(proxy.$t('corp.form.code_rule'))],
                name: [utils.UtilPub.commonValidRule(proxy.$t('corp.form.name_rule'))],
                tel: [{validator: checkTel, trigger: "blur"}]
            },
            otherParams:{
                disableField:false
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new CorpCardUtil(proxy,dataObj);
        })
        onMounted(()=>{

        })
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=> {
            dataObj.parentTreeData = await utils.Api.corpTreeData({showType:utils.Const.cascade});
            dataObj.otherParams.disableField=res.disableField;
            if(res.disabled!=undefined)dataObj.disabled=res.disabled;

        }
        //用于下拉select联动处理，每个select都包含有一个备份，所以可以在前端完成联动
        const selectOnChange=(newValue:string,selectId:string)=>{
            if('field1'==selectId){
                dataObj.form.field2='';
                dataObj.compParams.formItems.forEach((item:any)=>{
                    if(item.prop=='field2' && item.bakData){//找到下拉组件
                        newValue?(item.data=item.bakData.filter((item:any)=>item.item.corpId==newValue)):(item.data=item.bakData)
                    }
                })
            }
        }
        const startHandler=()=>{
            dataObj.utilInst.changeStatus({id: dataObj.form.id,type:'start',operateType:'changeStatus'});
        }
        const stopHandler=()=>{
            dataObj.utilInst.changeStatus({id: dataObj.form.id,type:'stop',operateType:'changeStatus'});
        }
        return{
            ...toRefs(dataObj),beforeOpen,selectOnChange,startHandler,stopHandler
        }
    },
    components: {}
});