import BaseBean from "@/utils/BaseBean";

export interface ICorpListDataObj {
    value:string
    options:Array<any>
    utilInst:CorpListUtil
    refMap:Map<string,any>
    pageListRef:any
    importGuidParams:any
    pageList: any
    otherParams: any
}

export default class CorpListUtil extends BaseBean{
    public dataObj:ICorpListDataObj

    constructor(proxy:any,dataObj:ICorpListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}