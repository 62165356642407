export default {
    zh:{
        corpCard:'机构管理',
        corp:{
            downLoadFileName:'机构导入模版.xlsx',
            queryParam:{
                code:'编码',
                code_placeholder:'请输入编码模糊匹配查询...',
                name:'名称',
                name_placeholder:'请输入名称模糊匹配查询...',
            },
            columns:{
                F_CODE:'编码',
                F_NAME:'名称',
                F_PARENT_NAME:'上级名称',
                F_ADDR:'地址',
                F_TEL:'电话',
                F_URL:'网址',
                F_STATUS:'状态',
            },
            form:{
                code:'编码',
                code_placeholder:'请输入编码',
                code_rule:'请输入编码',
                name:'名称',
                name_placeholder:'请输入名称',
                name_rule:'请输入名称',
                parentId:'上级机构',
                parentId_placeholder:'请选择上级机构',
                addr:'地址',
                addr_placeholder:'请输入地址',
                tel:'电话',
                tel_placeholder:'请输入电话',
                tel_rule:'请输入数字',
                fzr:'负责人',
                fzr_placeholder:'请输入负责人',
                url:'网站',
                url_placeholder:'请输入网站',
                desc:'说明',
                desc_placeholder:'请输入说明',
            }
        }
    },
    en:{
        corpCard:'organizational management',
        corp:{
            downLoadFileName:'corpImportTemplate.xlsx',
            queryParam:{
                code:'code',
                code_placeholder:'Please enter a code fuzzy matching query...',
                name:'name',
                name_placeholder:'Please enter a name fuzzy matching query...',
            },
            columns:{
                F_CODE:'code',
                F_NAME:'name',
                F_PARENT_NAME:'parent',
                F_ADDR:'address',
                F_TEL:'telephone',
                F_URL:'website',
                F_STATUS:'status',
            },
            form:{
                code:'code',
                code_placeholder:'Please enter a code',
                code_rule:'Please enter a code',
                name:'name',
                name_placeholder:'Please enter a name',
                name_rule:'Please enter a name',
                parentId:'parent',
                parentId_placeholder:'Please select parent organization',
                addr:'address',
                addr_placeholder:'Please enter an address',
                tel:'telephone',
                tel_placeholder:'Please enter a telephone',
                tel_rule:'Please enter number',
                fzr:'charge',
                fzr_placeholder:'Please enter a charge',
                url:'website',
                url_placeholder:'Please enter a website',
                desc:'explain',
                desc_placeholder:'Please enter explain',
            }
        }
    }
}